import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Tours from '../components/tours/tours';
import { useParams } from 'react-router-dom';

function ToursPage({ lang, setLang }) {
	const { title } = useParams();

	return (
		<>
			<div className='home_bgimg' style={{ paddingBottom: '50px' }}>
				<Header lang={lang} setLang={setLang} />
			</div>

			<main className='main'>
				<Tours lang={lang} title={title} />
			</main>

			<Footer lang={lang} />
		</>
	);
}

export default ToursPage;
