import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import About from '../components/about/about';
import Team from '../components/team/team';

function AboutPage({ lang, setLang }) {
	return (
		<>
			<div className='home_bgimg' style={{ paddingBottom: '50px' }}>
				<Header lang={lang} setLang={setLang} />
			</div>

			<About lang={lang} />
			<Team lang={lang} />
			<Footer lang={lang} />
		</>
	);
}

export default AboutPage;
