import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import TripLists from '../components/tripsList/tripsList';

function TripsPage({ lang, setLang }) {
	return (
		<>
			<div className='home_bgimg' style={{ paddingBottom: '50px' }}>
				<Header lang={lang} setLang={setLang} />
			</div>

			<TripLists lang={lang} />
			<Footer lang={lang} />
		</>
	);
}

export default TripsPage;
