import Header from '../components/header/header';
import SendMessage from '../components/contact/contact';
import Map from '../components/map/map';
import Footer from '../components/footer/footer';

function ContactPage({ lang, setLang }) {
	return (
		<>
			<div className='home_bgimg' style={{ paddingBottom: '50px' }}>
				<Header lang={lang} setLang={setLang} />
			</div>

			<SendMessage lang={lang} />
			<Map />
			<Footer lang={lang} />
		</>
	);
}

export default ContactPage;
