import './formFeedback.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { uid } from 'uid';
import { onValue, ref, set } from 'firebase/database';

function FormFeedback({ lang }) {
	const [data, setData] = useState([]);
	const [name, setName] = useState('');
	const [comment, setComment] = useState('');
	const [SlidesCount, setSlidesCount] = useState(3);

	function reveal() {
		var reveals = document.querySelectorAll('.reveal');

		for (var i = 0; i < reveals.length; i++) {
			var windowHeight = window.innerHeight;
			var elementTop = reveals[i].getBoundingClientRect().top;
			var elementVisible = 150;

			if (elementTop < windowHeight - elementVisible) {
				reveals[i].classList.add('active');
			} else {
				reveals[i].classList.remove('active');
			}
		}
	}

	window.addEventListener('scroll', reveal);

	useEffect(() => {
		if (window.innerWidth < 560) {
			setSlidesCount(1);
		} else if (window.innerWidth < 730) {
			setSlidesCount(1);
		} else if (window.innerWidth < 1060) {
			setSlidesCount(2);
		} else {
			setSlidesCount(3);
		}
	}, []);

	useEffect(() => {
		onValue(ref(db), (snapshot) => {
			const data = snapshot.val();
			if (data !== null) {
				Object.values(data).map((feedback) => {
					setData((oldData) => [...oldData, feedback]);
				});
			}
		});
	}, []);

	const addFeedback = (e) => {
		e.preventDefault();
		// const { name, comment } = e.target.elements;
		const uuid = uid();
		set(ref(db, `/${uuid}`), {
			name,
			comment,
		});

		setName('');
		setComment('');
	};

	return (
		<>
			<section className='feedback-form'>
				<div className='container reveal'>
					<div className='feedback-form__left-box'>
						<img
							src='https://basmalah.uz/thumb/2/tuWSTdyDmMW5h3rd_cW-Yw/1920r1080/d/54706241_2.jpg'
							alt='image'
						/>
					</div>

					<div className='feedback-form__middle-box'>
						<h2 className='feedback-form__heading'>Send Comment</h2>
						<form onSubmit={addFeedback}>
							<input
								className='feedback-form__input'
								type='text'
								name='name'
								placeholder='Your Name'
								required
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<textarea
								className='feedback-form__input'
								name='comment'
								placeholder='Your comment'
								required
								value={comment}
								onChange={(e) => setComment(e.target.value)}
							/>
							<button className='feedback-form__btn'>
								Send Comment
							</button>
						</form>
					</div>

					<div className='feedback-form__right-box'>
						<img
							src='https://basmalah.uz/thumb/2/l-H3FTgJYAl1CU_Ibx17GA/1920r1080/d/55326473_2.jpg'
							alt='image'
						/>
					</div>
				</div>
			</section>

			<section className='feedback'>
				<div className='container'>
					<Swiper
						slidesPerView={SlidesCount}
						spaceBetween={20}
						navigation={true}
						modules={[Navigation, Autoplay]}
						autoplay={{
							delay: 3000,
							disableOnInteraction: false,
						}}
						className='mySwiper'>
						{data.map((e, i) => {
							return (
								<SwiperSlide key={i}>
									<div className='feedback__box'>
										<h4 className='feedback__title'>{e.name}</h4>
										<p className='feedback__text'>{e.comment}</p>
									</div>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</div>
			</section>
		</>
	);
}

export default FormFeedback;
