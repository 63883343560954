import Header from '../components/header/header';
import HomeIntro from '../components/homeIntro/homeIntro';
import HomeTrip from '../components/homeTrip/homeTrip';
import HomeAbout from '../components/homeAbout/homeAbout';
import HomePopular from '../components/homePopular/homePopular';
import Advantages from '../components/advantages/advantages';
import Weather from '../components/weather/weather';
import FormFeedback from '../components/formFeedback/formFeedback';
import Map from '../components/map/map';
import Footer from '../components/footer/footer';

function HomePage({ lang, setLang }) {
	return (
		<>
			<div className='home_bgimg'>
				<Header lang={lang} setLang={setLang} />
				<HomeIntro lang={lang} />
			</div>

			<HomeTrip lang={lang} />
			<HomeAbout lang={lang} />
			<HomePopular lang={lang} />
			<Advantages lang={lang} />
			<FormFeedback lang={lang} />
			<Weather lang={lang} />
			<Map />
			<Footer lang={lang} />
		</>
	);
}

export default HomePage;
