import './map.scss';

function Map() {
	return (
		<section className='map-section'>
			<div className='map__box'>
				<iframe
					className='map'
					src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18584.28304570436!2d66.8847506005573!3d39.63509000051502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f4d191960077df7%3A0x487636d9d13f2f57!2sSamarkand%2C%20Uzbekistan!5e0!3m2!1sen!2s!4v1685287885558!5m2!1sen!2s'
					loading='lazy'
					style={{ border: 'none' }}></iframe>
			</div>
		</section>
	);
}

export default Map;