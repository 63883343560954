const content = {
   uz: {
      header: {
         welcome: "Asosiy",
         tours: "Ekskursiyalar",
         information: "Ma`lumotlar",
         about: "Biz haqimizda",
         contact: "Aloqa"
      },
   },

   ru: {
      header: {
         welcome: "Главная",
         tours: "Туры",
         information: "Информация",
         about: "О нас",
         contact: "Контакт"
      },
   },

   en: {
      header: {
         welcome: "Welcome",
         tours: "Tours",
         information: "Information",
         about: "About us",
         contact: "Contact us"
      },
   },

   fr: {
      header: {
         welcome: "Welcome",
         tours: "Tours",
         information: "Information",
         about: "About us",
         contact: "Contact us"
      },
   }
}

export default content