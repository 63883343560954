import './singleTours.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import img1 from '../../assets/images/emx8lkew4aavgaa-2-1024x728.jpg';
import img2 from '../../assets/images/img-04.jpg';
import img3 from '../../assets/images/samarkand-1024x661.jpg';
import img4 from '../../assets/images/photo_2023-03-30_08-15-30.jpg';
import img5 from '../../assets/images/51822697868_d933426b28_k-1024x459.jpg';
import img6 from '../../assets/images/259154-1024x680.jpg';
import img7 from '../../assets/images/screenshot_1103-1024x681.png';
import img8 from '../../assets/images/1660466905_7_kartinkin_net_p_kirgiziya_stolitsa_osh_krasivo_foto.jpg';
import img9 from '../../assets/images/1649125013_1_vsegda_pomnim_com_p_priroda_kirgizii_foto_1_1024x683.jpg';
import img10 from '../../assets/images/photo_2023-03-30_09-20-46.jpg';
import img11 from '../../assets/images/img-04-3.webp';
import img12 from '../../assets/images/1660340739_37-kartinkin-net-p-dushanbe-stolitsa-tadzhikistana-krasivo-fo-38-scaled.webp';
import img13 from '../../assets/images/i-1.webp';
import img14 from '../../assets/images/img-03-4-scaled.webp';
import img15 from '../../assets/images/i-1.webp';

const data = [
	{
		id: 1,
		title: 'TRIP TO THE COUNTRY OF TAMERLAN',
		image: img11,
		desc: 'Uzbekistan prospered at the crossroads of major caravan routes from China, Siberia, Persia and the West, until the surge of the hordes of Genghis Khan who destroyed the city',
		tour_country: 'Uzbekistan',
	},
	{
		id: 2,
		title: 'The Essentials Of Uzbekistan',
		image: img1,
		desc: 'In the footsteps of Marco Polo and the conquerors of Central Asia: the Persians, Alexander the Great, the Arabs, Genghis Khan...discover those that were long considered the',
		tour_country: 'Uzbekistan',
	},
	{
		id: 3,
		title: 'UZBEKISTAN – CROSSROADS OF CIVILIZATIONS',
		image: img2,
		desc: 'Uzbekistan, whose territory corresponds to the crossroads of the Silk Road, will offer you an exceptional discovery with its mythical and legendary cities such as Samarkand,',
		tour_country: 'Uzbekistan',
	},
	{
		id: 4,
		title: 'IN THE FOOTSTEPS OF MARCO POLO',
		image: img3,
		desc: 'In the footsteps of Marco Polo and the conquerors of Central Asia: the Persians, Alexander the Great, the Arabs, Genghis Khan...discover those that were long considered the',
		tour_country: 'Uzbekistan',
	},
	{
		id: 5,
		title: 'HIKING ON THE TIAN SHAN MOUNTAINS',
		image: img4,
		desc: 'Do you dream of an unexpected discovery at the heart of great travels, a complete change of scenery? Choose Karavan Travel for this beautiful discovery on the Tian Shan ranges and for an exceptional and unforgettable trip to the mythical cities of the Silk Road.',
		tour_country: 'Uzbekistan',
	},
	{
		id: 6,
		title: 'ARAL SEA – UZBEK DEAD SEA',
		image: img5,
		desc: 'Uzbekistan is a dream destination for travelers looking for an exotic and authentic escape. This country has a great cultural and natural wealth. It is also full of artistic and',
		tour_country: 'Uzbekistan',
	},
	{
		id: 7,
		title: 'DISCOVERY OF TAJIK AND UZBEK CITIES',
		image: img6,
		desc: 'STRONG POINTS : Hiking in the spectacular nature of the Fan Mountains of Tajikistan; Explore the magical cities of the Silk Road; Enjoy the exceptional mountain hospitality',
		tour_country: 'Tajikistan',
	},
	{
		id: 8,
		title: 'DISCOVER THE NORTHERN PART OF TAJIKISTAN AND UZBEKISTAN',
		image: img7,
		desc: 'STRONG POINTS : Hiking in the spectacular nature of the Fan Mountains of Tajikistan; Explore the magical cities of the Silk Road; Enjoy the exceptional mountain hospitality',
		tour_country: 'Tajikistan',
	},
	{
		id: 9,
		title: 'THE FANN MOUNTAINS OF TAJIKISTAN',
		image: img6,
		desc: 'STRONG POINTS : Hiking in the spectacular nature of the Fan Mountains of Tajikistan; Explore the magical cities of the Silk Road; Enjoy the exceptional mountain hospitality',
		tour_country: 'Tajikistan',
	},
	{
		id: 10,
		title: 'TREKKING IN THE HEART OF THE FANSKY MOUNTAINS',
		image: img12,
		desc: 'We offer you to discover the magnificent landscape of Tajikistan and the majestic monuments of Uzbekistan in a sporty way through trekking. A tour designed to see the',
		tour_country: 'Tajikistan',
	},
	{
		id: 11,
		title: 'UZBEKISTAN – CROSSROADS OF CIVILIZATIONS',
		image: img12,
		desc: 'Uzbekistan, whose territory corresponds to the crossroads of the Silk Road, will offer you an exceptional discovery with its mythical and legendary cities such as Samarkand',
		tour_country: 'Tajikistan',
	},
	{
		id: 12,
		title: 'THE WILD LANDSCAPES OF CENTRAL ASIA',
		image: img15,
		desc: 'Breakfast. Morning transfer to Sussamyr. The road skirts the valley of Kyrgyz Alatoo before threading in the canyon of Kara Balta, until the tunnel opening on the high valley of',
		tour_country: 'Kyrgyzstan',
	},
	{
		id: 13,
		title: 'THE FASCINATING MONUMENTS OF CENTRAL ASIA',
		image: img8,
		desc: 'This circuit, in the footsteps of Marco Polo and the conquerors of Central Asia: the Persians, Alexander the Great, the Arabs, Genghis Khan...discover those which were',
		tour_country: 'Kyrgyzstan',
	},
	{
		id: 14,
		title: 'JOURNEY TO THE LAND OF THE CELESTIAL HORSES',
		image: img9,
		desc: 'Breakfast. Morning transfer to Sussamyr. The road skirts the valley of Kyrgyz Alatoo before threading in the canyon of Kara Balta, until the tunnel opening on the high valley of',
		tour_country: 'Kyrgyzstan',
	},
	{
		id: 15,
		title: 'LEGENDARY CITIES OF THE SILK ROAD',
		image: img10,
		desc: 'Central Asia whose territory corresponds to the crossroads of the Silk Road will offer you an exceptional discovery with its mythical and legendary cities like Kounia',
		tour_country: 'Turkmeniston',
	},
	{
		id: 16,
		title: 'Wonders Of The Silk Road',
		image: img14,
		desc: 'Uzbekistan, whose territory corresponds to the crossroads of the Silk Road, will offer you an exceptional discovery with its mythical and legendary cities such as Samarkand,',
		tour_country: 'Turkmeniston',
	},
	{
		id: 17,
		title: 'KAZAKHSTAN, THE WILD LANDSCAPES OF CENTRAL ASIA',
		image: img13,
		desc: 'Breakfast. Morning transfer to Sussamyr. The road skirts the valley of Kyrgyz Alatoo before threading in the canyon of Kara Balta, until the tunnel opening on the high valley of',
		tour_country: 'Kazakhstan',
	},
];

function SingleTours({ lang, title }) {
	const [tour, setTour] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		const filter = data.filter((e) => e.tour_country === title);
		setTour(filter);
	}, [title]);

	return (
		<section className='single-tour'>
			<div className='container'>
				<h2 className='single-tour__heading'>{title}</h2>

				<ul className='single-tour__list'>
					{tour.map((e, i) => (
						<li
							className='single-tour__item'
							key={i}
							onClick={() => navigate(`/trip/${e.title}`)}>
							<img
								src={e.image}
								alt='image of tours'
								width={400}
								height={270}
							/>

							<div className='single-tour__item__box'>
								<h3 className='single-tour__item__title'>{e.title}</h3>
								<p className='single-tour__item__desc'>{e.desc}</p>
								<button
									className='single-tour__item__btn'
									onClick={() => navigate(`/trip/${e.title}`)}>
									More
								</button>
							</div>
						</li>
					))}
				</ul>
			</div>
		</section>
	);
}

export default SingleTours;
