import "./App.scss";
import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import HomePage from "./pages/home";
import TripsPage from "./pages/trips";
import InformationPage from "./pages/information";
import ContactPage from "./pages/contact";
import AboutPage from "./pages/about";
import SingleInfoPage from "./pages/singleInfo";
import SingleToursPage from "./pages/singleTours";
import ToursPage from "./pages/tours";

function App() {
  const { pathname } = useLocation();
  const [lang, setLang] = useState("en");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomePage lang={lang} setLang={setLang} />} />
      <Route
        path="/tours"
        element={<TripsPage lang={lang} setLang={setLang} />}
      />
      <Route
        path="/information"
        element={<InformationPage lang={lang} setLang={setLang} />}
      />
      <Route
        path="/contact"
        element={<ContactPage lang={lang} setLang={setLang} />}
      />
      <Route
        path="/about"
        element={<AboutPage lang={lang} setLang={setLang} />}
      />
      <Route
        path="/information/:info_title"
        element={<SingleInfoPage lang={lang} setLang={setLang} />}
      />
      <Route
        path="/tour/:tour_name"
        element={<SingleToursPage lang={lang} setLang={setLang} />}
      />
      <Route
        path="/trip/:title"
        element={<ToursPage lang={lang} setLang={setLang} />}
      />
    </Routes>
  );
}

export default App;
