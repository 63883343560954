import './advantages.scss';

function Advantages({ lang }) {
	return (
		<section className='advantages'>
			<div className='container'>
				<h2 className='advantages__heading'>Advantages:</h2>

				<div className='advantages__box'>
					<div className='advantages__left'>
						<ul className='left__list'>
							<li className='left__item'>
								<div className='item__info-box'>
									<h3 className='item__heading'>Лучший персонал</h3>
									<p className='item__text'>
										Ведущие специалисты с многолетним опытом работы в
										сфере туристических услуг
									</p>
								</div>
								<div className='item__img-box'>
									<img
										src='https://basmalah.uz/thumb/2/h6Vy3BsR886Cy-t7J_0ZQg/280r280/d/svgexport-12_2023-03-09t113417810.svg'
										alt='img'
										width={65}
										height={65}
									/>
								</div>
							</li>
							<li className='left__item'>
								<div className='item__info-box'>
									<h3 className='item__heading'>Все виды туров</h3>
									<p className='item__text'>
										Мы подберем то что вам нужно, Удобная витрина
										горящих туров
									</p>
								</div>
								<div className='item__img-box'>
									<img
										src='https://basmalah.uz/thumb/2/-aBM-JbQ98q_FITQaBxgiQ/280r280/d/svgexport-12_2023-03-09t113503649.svg'
										alt='img'
										width={65}
										height={65}
									/>
								</div>
							</li>
						</ul>
					</div>

					<div className='advantages__medium'>
						<img
							src='https://basmalah.uz/thumb/2/yRbtuLQ10so5Tr4Ioa8V_w/1920r1080/d/55326469_2.jpg'
							alt='img'
							width={500}
							height={500}
						/>
					</div>

					<div className='advantages__right'>
						<ul className='right__list'>
							<li className='right__item'>
								<div className='item__img-box'>
									<img
										src='https://basmalah.uz/thumb/2/OK0AZ-m_88f567q3FzQhZQ/280r280/d/svgexport-12_2023-03-09t113347441.svg'
										alt='img'
										width={65}
										height={65}
									/>
								</div>
								<div className='item__info-box'>
									<h3 className='item__heading'>
										Гарантия безопасности
									</h3>
									<p className='item__text'>
										Вы можете быть уверены в своей безопасности
									</p>
								</div>
							</li>
							<li className='right__item'>
								<div className='item__img-box'>
									<img
										src='https://basmalah.uz/thumb/2/JkFY_nxwFGBkcnnu-IY9Qg/280r280/d/svgexport-9_2023-03-09t113554313.svg'
										alt='img'
										width={65}
										height={65}
									/>
								</div>
								<div className='item__info-box'>
									<h3 className='item__heading'>Доступные цены</h3>
									<p className='item__text'>
										Самые низкие и актуальные цены в Ташкенте и по
										всему Узбекистану
									</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Advantages;
