import { useParams } from 'react-router-dom';

import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import SingleInfo from '../components/singleInfo/singleInfo';

function SingleInfoPage({ setLang, lang }) {
	const { info_title } = useParams();

	return (
		<>
			<div className='home_bgimg' style={{ paddingBottom: '50px' }}>
				<Header lang={lang} setLang={setLang} />
			</div>

			<main className='main'>
				<SingleInfo lang={lang} title={info_title} />
			</main>

			<Footer lang={lang} />
		</>
	);
}

export default SingleInfoPage;
