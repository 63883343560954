import './homeIntro.scss';
import { useNavigate } from 'react-router-dom';
import content from '../../Localization/content';

function HomeIntro({ lang }) {
	const naviagte = useNavigate();

	function reveal() {
		var reveals = document.querySelectorAll('.reveal');
		var btn = document.querySelectorAll('.btn-active');
		var btn2 = document.querySelectorAll('.navigate__btn-box');

		for (var i = 0; i < reveals.length; i++) {
			var windowHeight = window.innerHeight;
			var elementTop = reveals[i].getBoundingClientRect().top;
			var elementVisible = 150;

			if (elementTop < windowHeight - elementVisible) {
				if (btn) {
					return btn2[i]?.classList.remove('btn-active')
				}
			} else {
				btn2[i]?.classList.add('btn-active');
			}
		}
	}

	window.addEventListener('scroll', reveal);

	return (
		<section className='home-intro reveal'>
			<div className='container'>
				<div className='home-intro__box'>
					<h1 className='home-intro__heading'>
						<span className='s2'>
							To stay is to exist. To travel is to live
						</span>
						<br />
						<span className='s1'>
							Traveling first leaves you speechless, before turning you
							into a storyteller. (Ibn Battuta)
						</span>
					</h1>
					<p className='home-intro__text'>
						Travel has helped us to understand the meaning of life and it
						has helped us become better people. Each time we travel, we
						see the world with new eyes.
					</p>
					<button
						className='home-intro__btn'
						onClick={() => naviagte('/contact')}>
						НАПИШИТЕ НАМ
					</button>
				</div>
			</div>
		</section>
	);
}

export default HomeIntro;
