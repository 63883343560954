import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
   apiKey: "AIzaSyB7q7I9j5qj1b0mefKFvEfWxHVAK0VyggU",
   authDomain: "travel-bbe0c.firebaseapp.com",
   projectId: "travel-bbe0c",
   storageBucket: "travel-bbe0c.appspot.com",
   messagingSenderId: "181129848180",
   appId: "1:181129848180:web:a7d578eb056b3f999ff821",
   measurementId: "G-0YQWCQDPMX"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app )

// https://travel-bbe0c-default-rtdb.firebaseio.com/