import { useParams } from 'react-router-dom';

import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import SingleTours from '../components/singleTours/singleTours';

function SingleToursPage({ lang, setLang }) {
	const { tour_name } = useParams();

	return (
		<>
			<div className='home_bgimg' style={{ paddingBottom: '50px' }}>
				<Header lang={lang} setLang={setLang} />
			</div>

			<main className='main'>
				<SingleTours lang={lang} title={tour_name} />
			</main>

			<Footer lang={lang} />
		</>
	);
}

export default SingleToursPage;
