import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import img1 from '../../assets/images/5ee20a531d601.webp';
import img2 from '../../assets/images/5ede9568c7503.webp';
import img3 from '../../assets/images/5ea533407268d.webp';
import img4 from '../../assets/images/5ea5305197126.webp';
import img5 from '../../assets/images/5ea52ffa9aacb.webp';
import img6 from '../../assets/images/5ea52a93caeaf.webp';
import img7 from '../../assets/images/6-1.webp';
import img8 from '../../assets/images/img-04-3.webp';
import img9 from '../../assets/images/photo_2023-03-12_19-59-06.webp';

const data = [
	{
		id: 1,
		title: 'GENERAL BOOKING CONDITIONS',
		image: img1,
		desc: 'Prices Voyage Avicenne reserves the right to modify the prices of the circuits at any time before your reservation. If the government imposes new taxes',
	},
	{
		id: 2,
		title: 'WHEN TO GO TO UZBEKISTAN',
		image: img2,
		desc: 'For trips to Uzbekistan, you can plan for spring, from April or May. The weather is mild, the oases are blooming and green,',
	},
	{
		id: 3,
		title: 'FORMALITIES',
		image: img3,
		desc: 'Visa From October 5, 2018 citizens of the French Republic benefit from a visa-free regime to enter Uzbekistan for a period of',
	},
	{
		id: 4,
		title: 'Geography',
		image: img4,
		desc: 'Uzbekistan is located in an arid zone dominated by deserts. That of Kyzyl Kum (red sand), covers two thirds of the country and',
	},
	{
		id: 5,
		title: 'HEALTH',
		image: img5,
		desc: "Most of the country's health problems are linked to the environmental consequences of the cotton monoculture policy imposed by the Soviets.",
	},
	{
		id: 6,
		title: 'FOOD',
		image: img6,
		desc: 'Restaurants Restaurants in the Western sense are rare in Uzbekistan. Concentrated in Tashkent and Samarkand, we find none in the rest.',
	},
	{
		id: 7,
		title: 'TRAVEL SAFETY IN UZBEKISTAN',
		image: img7,
		desc: 'Woman traveling alone in Uzbekistan: there is no major problem for a woman traveling alone in Uzbekistan. In local transport,',
	},
	{
		id: 8,
		title: 'WHAT TO KNOW ABOUT UZBEKISTAN',
		image: img8,
		desc: 'Way of life and traditions Uzbekistan is a Sunni Muslim country, but which remained separated from the rest of the Muslim world by the appearance of a power',
	},
	{
		id: 9,
		title: 'Learn French In An Uzbek Village',
		image: img9,
		desc: 'Founder of the “Voyage Avicenne” agency, I was born and learned French at the school in my village, Mayintepa. A long time ago, back then',
	},
];

function InfoLists({ lang }) {
	const navigate = useNavigate();
	const [populars, setPopulars] = useState(data);

	function reveal() {
		var reveals = document.querySelectorAll('.reveal');

		for (var i = 0; i < reveals.length; i++) {
			var windowHeight = window.innerHeight;
			var elementTop = reveals[i].getBoundingClientRect().top;
			var elementVisible = 150;

			if (elementTop < windowHeight - elementVisible) {
				reveals[i].classList.add('active');
			} else {
				reveals[i].classList.remove('active');
			}
		}
	}

	window.addEventListener('scroll', reveal);

	return (
		<section className='home-popular'>
			<div className='container'>
				<h2
					className='home-popular__heading'
					style={{ textAlign: 'center' }}>
					Information
				</h2>
				<p className='location'>
					<Link to='/'>Home</Link> /
					<Link to='/information'>Information</Link>
				</p>

				<ul className='home-popular__list reveal'>
					{populars &&
						populars.map((e, i) => (
							<li className='home-popular__item' key={i}>
								<img
									src={e.image}
									alt='image of tours'
									width={400}
									height={270}
								/>

								<div className='home-popular__item__box'>
									<div>
										<h3 className='home-popular__item__title'>
											{e.title}
										</h3>

										<button
											className='home-popular__item__btn'
											onClick={() =>
												navigate(`/information/${e.title}`)
											}>
											More
										</button>
									</div>
								</div>
							</li>
						))}
				</ul>
			</div>
		</section>
	);
}

export default InfoLists;
