import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import InfoLists from '../components/infoLists/infoLists';

function InformationPage({ lang, setLang }) {
	return (
		<>
			<div className='home_bgimg' style={{ paddingBottom: '50px' }}>
				<Header lang={lang} setLang={setLang} />
			</div>

			<InfoLists lang={lang} />
			<Footer lang={lang} />
		</>
	);
}

export default InformationPage;
